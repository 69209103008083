.btn {
  background-color: red;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.link:hover {
  cursor: pointer;
  /* color: #ffde59; */
}
.underline::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.underline:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.underline::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: #ffde59;
  z-index: -1;
  transition: transform 0.4s ease;
}

.underline {
  position: relative;
  text-decoration: none;
  color: white;
}
