.animate::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.animate:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.animate::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: #ffde59;
  z-index: -1;
  transition: transform 0.3s ease;
}

.animate {
  position: relative;
  /* font-size: 5rem; */
  text-decoration: none;
  color: white;
}

.image {
  object-fit: cover;
  max-width: 70%;
  max-height: 40vh;
  display: flex;
  align-self: center;
  margin-bottom: 18px;
}
